.app-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader img {
    height: 60px;
}
