.welcome-bar-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 3% 10%;
  animation: fade-in 1s ease-in-out 0.1s;
  animation-fill-mode: backwards;
}

h1 {
  font-size: 45px;
  margin-bottom: 5px;
  font-weight: 700;
}
.quote {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.quote h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-style: italic;
}
.quote h4 {
  margin-top: 0;
  font-weight: 500;
}

.stores-badges {
  margin-top: 30px;
}
.stores-badges img {
  height: 55px;
}
.stores-badges img+img {
  margin-left: 30px;
}
@media screen and (width <= 768px) {
  h1 {
    font-size: 32px;
  }
  .quote h3 {
    font-size: 15px;
  }

  .quote h4 {
    font-size: 14px;
  }
  .stores-badges {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 30px;
    flex-direction: column;
  }
  .stores-badges img {
    width: 180px;
    height: auto;
  }
  .stores-badges img+img {
    margin-left: 0;
    margin-top: 25px;
    margin-bottom: 30px;
  }
}
