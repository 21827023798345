.modal-wrapper{
  transition: opacity 0.2s ease-in,
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_window {
  position: relative;
  background-color: white;
  width: 500px;
  height: 25%;
  padding: 30px;
  border-radius: 10px;
}

.modal_close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
}

[data-theme="dark"] {
  .modal_window {
    background-color: rgb(31, 31, 31);
  }
}

@media screen and (width <=768px) {
  .modal_window {
    width: 80%;
  }
}
