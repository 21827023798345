.app-shots {
  display: flex;
  justify-content: center;
  animation: fade-in 1s ease-in-out 0.2s;
  animation-fill-mode: backwards;
  margin-top: auto;
}

.mobile-screen {
  display: none;
}

@media screen and (width <= 768px) {
  .desktop-screen {
    display: none;
  }
  .mobile-screen {
    width: 55%;
    display: block;
  }
}

@media screen and (width > 768px) and (width <= 1199px) {
  .app-shots img {
    width: 800px;
  }
}
