@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css');

body {
  margin: 0;
  font-family: "Nunito", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: rgb(26, 26, 26);
  background:
    radial-gradient(circle, #00000000 0%, #2d2d2d30 90%, #2d2d2d30 100%),
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='0 0 4 4'%3E%3Cpath fill='%23ededed' fill-opacity='0.5' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s ease, color 0.3s ease;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: rgb(26, 26, 26);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-theme="dark"] {
  body {
    background: radial-gradient(circle, #00000000 0%, #2d2d2d30 90%, #2d2d2d30 100%);
    background-color: #071422;
    color: #dadada;
  }
  a {
    cursor: pointer;
    text-decoration: none;
    color: #dadada;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
