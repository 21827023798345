.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ededed10;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
  animation: fade-in 1s;
  animation-fill-mode: backwards;
}

.menu-bar {
  display: flex;
  align-items: center;
}

.menu-link-group {
  display: flex;
}
.menu-link-group span {
  display: flex;
  padding-right: 30px;
  cursor: pointer;
}
.mobile-link-group {
  display: flex;
  flex-direction: column;
  padding: 25px 35px;
  font-size: 20px;
  line-height:40px;
}

.menu-link-group span+span:before {
  display: flex;
  align-items: center;
  font-size: 10px;
  content: "•";
  padding-right: 30px;
}

.menu-icon {
  display: none;
}

@media screen and (width <= 768px) {
  .header {
    margin: 20px;
    padding: 10px 10%;
  }

  .menu-bar {
    width: 10%;
    height: 40px;
    border-radius: 5px;
  }
  .menu-link-group {
    display: none;
  }
  .menu-icon {
    display: flex;
    font-size: 30px;
  }
}

@media screen and (width > 768px) and (width <= 1199px) {
  .header {
    margin: 20px;
    padding: 10px 10%;
  }
}

@media screen and (width >= 1200px) {
  .header {
    margin: 20px;
    padding: 10px 20%;
  }
}
